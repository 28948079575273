
import { myIF, myRC, myART } from "print/data.js";
import { myLogo, imgqrcode } from "print/data.js";
var dataimg;



function mmTopx (mm) {
  var div = document.createElement("div");
  div.style.display = "block";
  div.style.height = "100mm";
  document.body.appendChild(div);
  var convert = (div.offsetHeight * mm) / 100;
  div.parentNode.removeChild(div);
  return convert;
}
export function marges2 (doc, store) {
  let doc_marges = {}
  doc_marges.margin_top = store.state.options[0].doc_top;
  doc_marges.margin_left = store.state.options[0].doc_left;
  doc_marges.margin_right = store.state.options[0].doc_right;
  doc_marges.margin_bottom = store.state.options[0].doc_bottom;
  let coef =
    doc.internal.pageSize.width / mmTopx(doc.internal.pageSize.width);
  doc_marges.l_w = 0
  doc_marges.l_h = 0
  if (myLogo) {
    dataimg = myLogo;
    const imgProps = doc.getImageProperties(dataimg);
    doc_marges.l_w = imgProps.width * coef;
    doc_marges.l_h = imgProps.height * coef;
  }
  doc_marges.p_w = 200
  doc_marges.p_h = 25


  return doc_marges
}
export const addHeaders = (doc, store) => {
  const pageCount = doc.internal.getNumberOfPages();
  let marges = marges2(doc, store)


  doc.setFont("helvetica", "italic");
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);

    if (myLogo) {
      dataimg = myLogo;
      doc.addImage(
        dataimg,
        "png",
        marges.margin_left,
        marges.margin_top - 10,
        marges.l_w,
        marges.l_h
      );

    }




    // doc.setLineWidth(0.2);
    // doc.setDrawColor(0, 0, 0);
    // doc.line(
    //   marges.margin_left,
    //   marges.margin_top + marges.l_h - 8,
    //   doc.internal.pageSize.width - marges.margin_right,
    //   marges.margin_top + marges.l_h - 8
    // );



  }
};
export const addFooters = (doc, store) => {
  var pageCount = doc.internal.getNumberOfPages();

  let nif = myIF;
  let rc = myRC;
  let art = myART;

  let marges = marges2(doc, store)
  doc.setFont("helvetica", "italic");
  doc.setFontSize(7);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setLineWidth(0.2);
    doc.setDrawColor(0, 0, 0);
    doc.line(
      marges.margin_left,
      doc.internal.pageSize.height - marges.margin_bottom - 15,
      doc.internal.pageSize.width - marges.margin_right,
      doc.internal.pageSize.height - marges.margin_bottom - 15
    );


    doc.text(
      "Siége: LSP 545 log ilot 532 Bat 18 N°1 Staoueli",
      marges.margin_left,
      doc.internal.pageSize.height - marges.margin_bottom - 10,
      {
        align: "left",
      }
    );
    doc.text(
      "Tél: 023 94 74 52  Fax.: 023 94 74 50",
      marges.margin_left,
      doc.internal.pageSize.height - marges.margin_bottom - 7,
      {
        align: "left",
      }
    );
    doc.text(
      "Email: info@packcare-dz.com",
      marges.margin_left,
      doc.internal.pageSize.height - marges.margin_bottom - 4,
      {
        align: "left",
      }
    );
    doc.text(
      "web: www.packcare-dz.com",
      marges.margin_left,
      doc.internal.pageSize.height - marges.margin_bottom - 1,
      {
        align: "left",
      }
    );

    doc.text(
      "Capital Social : 1 000 000.00 DA",
      marges.margin_left + 60,
      doc.internal.pageSize.height - marges.margin_bottom - 10,
      {
        align: "left",
      }
    );
    doc.text(
      "RC : " + rc,
      marges.margin_left + 60,
      doc.internal.pageSize.height - marges.margin_bottom - 7,
      {
        align: "left",
      }
    );
    doc.text(
      "NIF : " + nif,
      marges.margin_left + 60,
      doc.internal.pageSize.height - marges.margin_bottom - 4,
      {
        align: "left",
      }
    );
    doc.text(
      "ART : " + art,
      marges.margin_left + 60,
      doc.internal.pageSize.height - marges.margin_bottom - 1,
      {
        align: "left",
      }
    );

    doc.text(
      "Banque : BDL 00157",
      marges.margin_left + 106,
      doc.internal.pageSize.height - marges.margin_bottom - 10,
      {
        align: "left",
      }
    );
    doc.text(
      "Adresse : ZERALDA ",
      marges.margin_left + 106,
      doc.internal.pageSize.height - marges.margin_bottom - 7,
      {
        align: "left",
      }
    );
    doc.text(
      "Rue Banfaddah Aissa",
      marges.margin_left + 117,
      doc.internal.pageSize.height - marges.margin_bottom - 4,
      {
        align: "left",
      }
    );
    doc.text(
      "CB : 00157 0000006687",
      marges.margin_left + 106,
      doc.internal.pageSize.height - marges.margin_bottom - 1,
      {
        align: "left",
      }
    );

    doc.addImage(imgqrcode, "png", marges.margin_left + 165, doc.internal.pageSize.height - marges.margin_bottom - 14, 15, 15)

  }
};
export const addFootersFactList = (doc, store, pageStart, pageCount) => {

  let marges = marges2(doc, store)
  doc.setFont("helvetica", "italic");
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i * pageStart);


    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - marges.margin_bottom,
      {
        align: "center",
      }
    );
  }
};

